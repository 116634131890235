@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply antialiased;
}

#footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 0.6rem;
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}
