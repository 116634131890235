.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-button {
  background-color: #61dafb;
  padding: 10px;
  font-size: calc(2vmin);
  border: 1px solid black;
}

.App-button:focus {
  outline: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    animation-play-state: paused;
  }

  .App-logo-animate {
    animation-play-state: running;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3 {
  color: rgba(79, 70, 229, 0.7);
}

.react-datepicker__input-container input {
   width: 100%;
}